<template>
  <div>
    <c-table
      ref="table"
      title="체크리스트 목록"
      :columns="grid.columns"
      :data="cto.ctoChecklistModels"
      :filtering="false"
      :isExcelDown="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="체크리스트를 추가하세요."
      :hideBottom="true"
      :editable="editable&&!disabled"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='selectValue'">
          <c-select
            stype="tableselect"
            v-if="!props.row.disable"
            :disabled="disabled"
            codeGroupCd="CTO_GOOD_BAD_CD"
            :editable="editable"
            type="edit"
            itemValue="code"
            itemText="codeName"
            v-model="props.row['selectValue']"
            @datachange="datachange(props)"
            >
          </c-select>
        </template>
        <template v-if="col.name==='remarks'">
          <c-textarea-column
            v-if="!props.row.disable"
            :editable="editable"
            :disabled="disabled"
            :col="col"
            :props="props"
            v-model="props.row['remarks']"
            @datachange="datachange(props)"
          />
        </template>
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="sopCtoChecklistId"
            ibmTaskTypeCd="ITT0000060"
            ibmTaskUnderTypeCd="ITTU000095"
            @imprChange="imprChange"
          />
        </template>
      </template>
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && !disabled && cto.ctoChecklistModels.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="cto.ctoChecklistModels"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveChecklist"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'ctoChecklist',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
      }),
    },
    cto: {
      type: Object,
      default: () => ({
        sopCtoId: '',
        plantCd: '',
        ctoDate: '',
        ctoTime: '',
        observeUserId: '',
        observeCount: '',
        mdmSopId: '',
        workArea: '',
        ctoCompleteFlag: 'N',
        improveContents: '',
        priorityContents: '',
        managerUserId: '',
        sopName: '',
        processName: '',
        regUserId: '',
        chgUserId: '',
        workCharacteristics: '',
        humanFactors: '',
        coreActionItems: '',
        activityModels: [],
        ctoChecklistModels: [],
        ctoImproveModels: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'checklistName',
            field: 'checklistName',
            label: '체크리스트 항목',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: "selectValue",
            field: "selectValue",
            label: "양호/불량/해당없음",
            style: 'width:100px',
            type: 'custom',
            headType: 'select',
            align: "center",
            setHeader: true,
            codeGroupCd: 'CTO_GOOD_BAD_CD',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            type: 'custom',
            style: 'width:400px',
            align: 'left',
            sortable: false
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            style: 'width:250px',
            type: 'custom',
            sortable: false
          },
        ],
      },
      editable: true,
      isSave: false,
      saveUrl: '',
      saveType: 'POST',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checklistName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.cto.checklist.save.url;
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */ 
    saveChecklist() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.cto.ctoChecklistModels, item => {
            item.regUserId = this.$store.getters.user.userId
            item.chgUserId = this.$store.getters.user.userId

            item.sopCtoId = this.popupParam.sopCtoId
          })
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    // 개선창 닫기 후
    imprChange() {
      this.$emit('getDetail');
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
