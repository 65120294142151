var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "체크리스트 목록",
            columns: _vm.grid.columns,
            data: _vm.cto.ctoChecklistModels,
            filtering: false,
            isExcelDown: false,
            columnSetting: false,
            usePaging: false,
            noDataLabel: "체크리스트를 추가하세요.",
            hideBottom: true,
            editable: _vm.editable && !_vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "selectValue"
                    ? [
                        !props.row.disable
                          ? _c("c-select", {
                              attrs: {
                                stype: "tableselect",
                                disabled: _vm.disabled,
                                codeGroupCd: "CTO_GOOD_BAD_CD",
                                editable: _vm.editable,
                                type: "edit",
                                itemValue: "code",
                                itemText: "codeName",
                              },
                              on: {
                                datachange: function ($event) {
                                  return _vm.datachange(props)
                                },
                              },
                              model: {
                                value: props.row["selectValue"],
                                callback: function ($$v) {
                                  _vm.$set(props.row, "selectValue", $$v)
                                },
                                expression: "props.row['selectValue']",
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  col.name === "remarks"
                    ? [
                        !props.row.disable
                          ? _c("c-textarea-column", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.disabled,
                                col: col,
                                props: props,
                              },
                              on: {
                                datachange: function ($event) {
                                  return _vm.datachange(props)
                                },
                              },
                              model: {
                                value: props.row["remarks"],
                                callback: function ($$v) {
                                  _vm.$set(props.row, "remarks", $$v)
                                },
                                expression: "props.row['remarks']",
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: _vm.editable && !_vm.disabled,
                            isImmShow: true,
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "sopCtoChecklistId",
                            ibmTaskTypeCd: "ITT0000060",
                            ibmTaskUnderTypeCd: "ITTU000095",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.cto.ctoChecklistModels.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.cto.ctoChecklistModels,
                          mappingType: "POST",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveChecklist,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }